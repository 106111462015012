<template>
  <SfOBreadcrumbs
    class="sf-breadcrumbs"
    :class="{'desktop-only': !isShowOnMobile }"
    :breadcrumbs="breadcrumbs"
  >
    <template #link="{breadcrumb}">
      <router-link :to="breadcrumb.route.link" class="sf-breadcrumbs__breadcrumb">
        {{ breadcrumb.text }}
      </router-link>
    </template>
  </SfOBreadcrumbs>
</template>

<script>
import SfOBreadcrumbs from 'theme/components/storefront-override/SfOBreadcrumbs';
import { mapGetters } from 'vuex'
import { htmlDecode } from '@vue-storefront/core/filters'
import { localizedRoute } from '@vue-storefront/core/lib/multistore'

export default {
  name: 'MBreadcrumbs',
  props: {
    isShowOnMobile: {
      type: Boolean,
      default: true
    }
  },
  components: {
    SfOBreadcrumbs
  },
  computed: {
    ...mapGetters({
      getBreadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes',
      getBreadcrumbsCurrent: 'breadcrumbs/getBreadcrumbsCurrent'
    }),
    breadcrumbs () {
      const mappedRoutes = this.getBreadcrumbsRoutes.map(route => ({
        text: htmlDecode(route.name),
        route: {
          link: route.route_link
        }
      }))

      return [
        {
          route: {
            link: localizedRoute('/')
          },
          text: this.$i18n.t('Homepage')
        },
        ...mappedRoutes,
        { text: htmlDecode(this.getBreadcrumbsCurrent) }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/fonts";

.sf-breadcrumbs {
  padding: 0;
  min-height: 22px;

  .sf-breadcrumbs__breadcrumb {
    color: var( --black);
    font-size: 13px;
    line-height: 16px;
    font-family: var(--font-family-inter);
  }

  @include for-desktop {
    padding: var(--spacer-10) var(--spacer-base) var(--spacer-base) var(--spacer-sm);
  }

  ::v-deep .sf-breadcrumbs__list-item {
    @include for-mobile {
      padding: var(--spacer-15) 0 var(--spacer-15) var(--spacer-7);

      &:nth-last-child(-n+2) {
        display: inline-block;

        a {
          position: relative;
          padding-left: 18px;
          color: var(--dark-gray);
        }

        &:before {
          display: none;
        }
      }

      .sf-breadcrumbs__breadcrumb {
        @include font-icon(var(--icon-arrow-bold-left));

        &:before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: block;
          color: var(--black);
          font-size: var(--font-size-12);
        }
      }

      display: none;
    }
    &:last-child {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &[aria-current="page"] {
      display: none;

      @include for-desktop {
        display: inline-block;
      }
    }
  }
}
::v-deep {
  .sf-breadcrumbs__list {
    white-space: nowrap;

    &-item:not([aria-current="page"]) {
      &:hover {
        .sf-breadcrumbs__breadcrumb {
          color: var(--orange);
        }
      }
    }
  }

  .breadcrumbs {
    padding-top: 10px;
  }

  .sf-breadcrumbs__list-item:not(:first-child)::before {
    content: '–';
    padding: 0 0 0 4px;
    color: var(--black);
  }

  .sf-breadcrumbs__breadcrumb--current {
    color: var(--dark-gray);
    font-size: 13px;
    font-family: var(--font-family-inter);
  }
}
</style>
