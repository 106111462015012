<template>
  <div class="m-sidebar-mobile-buttons mobile-only">
    <SfButton
      class="sf-button--text m-sidebar-mobile-buttons__button m-sidebar-mobile-buttons__button--categories"
      :class="{ '_active': categoriesAreShown }"
      @click="toggleCategories"
    >
      {{ categoriesTitle || $t("Categories") }}
    </SfButton>
    <SfButton
      v-if="!disableFilters"
      class="sf-button--text m-sidebar-mobile-buttons__button m-sidebar-mobile-buttons__button--filters"
      @click="openFilter"
    >
      <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.9 0h6.6c1.2 0 2 1.4 1.2 2.3l-1 1.5H8.5V6h5.6l-2.4 3.5c-.2.2-.3.5-.3.8v4c0 .7-.7 1.4-1.5 1.4H7.8c-.8 0-1.5-.7-1.5-1.5v-3.8c0-.4 0-.7-.3-1l-5.7-7C-.4 1.4.3 0 1.5 0h7.4Z" fill="#fff" />
      </svg>
      <span class="m-sidebar-mobile-buttons__title">
        {{ filtersTitle || $t("Filters") }}
      </span>
      <span
        class="m-sidebar-mobile-buttons__count"
        v-if="filtersCount > 0"
      >
        {{ filtersCount }}
      </span>
    </SfButton>
  </div>
</template>
<script>
import {
  SfButton
} from '@storefront-ui/vue';

export default {
  name: 'MSidebarMobileButtons',
  components: {
    SfButton
  },
  props: {
    disableFilters: {
      type: Boolean,
      default: false
    },
    filtersCount: {
      type: Number,
      default: null
    },
    categoriesTitle: {
      type: String,
      default: ''
    },
    filtersTitle: {
      type: String,
      default: ''
    },
    categoriesAreShown: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    openFilter () {
      this.$emit('open')
    },
    toggleCategories () {
      this.isActive = !this.isActive
      this.$emit('toggle-categories')
    }
  }
};
</script>

<style lang="scss" scoped>

.m-sidebar-mobile-buttons {
  display: flex;
  margin-bottom: 2px;

  &__button {
    max-height: 50.8px;
    color: var(--white);
    flex: 1 1 50%;
    padding: 17px var(--spacer-base);
    background-color: var(--orange);
    text-decoration: none;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    border-radius: 5px;
    position: relative;
    font-size: var(--font-size-14);

    &:after {
      content: '';
      width: .3rem;
      height: .3rem;
      border-bottom: 2px solid var(--white);
      border-right: 2px solid var(--white);
      transform: rotate(45deg);
      right: var(--spacer-sm);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &--categories {
      background-color: var(--pale-orange2);
      color: var(--black);
      margin-right: 7px;
      &._active {
        &:after {
          transform: rotate(-135deg);
        }
      }
      &:after {
        border-bottom: 2px solid var(--black);
        border-right: 2px solid var(--black);
      }
    }

    &--filters {
      &:after {
        display: none;
      }
    }
  }

  &__title {
    display: inline-flex;
    padding: 0 var(--spacer-xs);
  }

  &__count {
    position: relative;
    left: var(--spacer-15);
    background-color: rgba(255,255,255, .3);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.875rem;
    height: 1.875rem;
  }
}
</style>
